import * as React from 'react';
import { StyleSheet, Text, View, Dimensions, SafeAreaView } from 'react-native';

import { Provider } from 'react-native-paper';

import * as Linking from 'expo-linking';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
const Stack = createNativeStackNavigator();
    
import * as Font from 'expo-font';
import { IbarraRealNova_400Regular, IbarraRealNova_700Bold, IbarraRealNova_400Regular_Italic } from '@expo-google-fonts/ibarra-real-nova';
let customFonts = {
  "IbarraRealNova_400Regular": IbarraRealNova_400Regular,
  "IbarraRealNova_700Bold": IbarraRealNova_700Bold,
  "IbarraRealNova_400Regular_Italic": IbarraRealNova_400Regular_Italic,
};

import Svg, {
  Path,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

const { width } = Dimensions.get("screen");
  
import Home from './src/components/Home';
import ResetPass from './src/components/ResetPass';
import Privacy from './src/components/Privacy';
import Terms from './src/components/Terms';
import Verified from './src/components/Verified';
import Order2 from './src/components/order2';

import By from './src/core/trade';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fontsLoaded: false,

      mobile: true,
    }
  }

  linking = {
    prefixes: [Linking.createURL('/'), 'https://berichmember.com'],
    config: {
      initialRouteName: "Home",
      screens: {
        ResetPass: {
          path: "resetpasscode"
        },
        Privacy: {
          path: "privacy"
        },
        Terms: {
          path: "terms"
        },
        Verified: {
          path: "verification"
        },
        Order2: {
          path: "richcard"
        },
      } 
    }
  };

  async _loadFontsAsync() {
    await Font.loadAsync(customFonts).then(()=>{
      this.setState({ fontsLoaded: true });
      console.log('fontsLoaded: true')
    })
  }

  componentDidMount(){
    console.log('HEJ!')
    this._loadFontsAsync()

    this.unsub = window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    this.handleWindowSizeChange()
  }

  componentWillUnmount(){
    this.unsub()
  }

  handleWindowSizeChange(){
    console.log(window.innerWidth)
    if(window.innerWidth <= 768){
      console.log('PHONE!!!')
      this.setState({mobile: true})
    }else{
      this.setState({mobile: false})
    }
  }

  render(){
    const { fontsLoaded } = this.state

    const mobile = this.state.mobile

    if(fontsLoaded===false){
      return(
        <View style={{flex:1, backgroundColor:'black'}}>
           <View style={{flex:1, justifyContent:'center'}}>
              <Text style={styles.titleText2}>App</Text>
            </View>
        </View>
      )
    }else{
      return(
        <Provider>
        <SafeAreaView style={{ flex:1, backgroundColor: "#CED1D0" }}>

          <View style={{ flex:1, position:'absolute', zIndex:1, justifyContent:'center', height:'100%', width:'100%'}}>
          <View style={{ width:mobile?'100%':'80%', height:mobile?'100%':'80%', alignSelf:'center' }}>
            <NavigationContainer linking={this.linking} theme={/*theme==='dark'?'*/DarkTheme/*:DefaultTheme*/}>
              <Stack.Navigator initialRouteName='Home'>
 
                <Stack.Screen   
                  name="Home" 
                  component={Home}
                  //initialParams={{user: this.state.user, new:this.state.new}}
                  options={{ headerShown: false, title: 'BeRich.'}}
                />
          
                <Stack.Screen 
                  name="ResetPass" 
                  component={ResetPass} 
                  options={{ headerShown: false, title: 'BeRich.'}}
                />
 
                <Stack.Screen  
                  name="Privacy"
                  component={Privacy}
                  options={{ headerShown: false, title: 'BeRich.'}}
                />

                <Stack.Screen
                  name="Terms"
                  component={Terms}
                  options={{ headerShown: false, title: 'BeRich.'}}
                /> 

                <Stack.Screen  
                  name="Verified"
                  component={Verified}
                  options={{ headerShown: false, title: 'BeRich.'}}
                /> 

                <Stack.Screen  
                  name="Order2"
                  component={Order2}
                  options={{ headerShown: false, title: 'BeRich.'}}
                /> 

              </Stack.Navigator>
            </NavigationContainer>
            <By/>
          </View>
          </View>

          {mobile?null:
          <View style={{ flex: 1, backgroundColor:'#CED1D0'}}>
          <Svg preserveAspectRatio="xMaxYMid slice" viewBox={"0 0 " + `${1500}` + " " + `${1000}`} fill="none" xmlns="http://www.w3.org/2000/svg">
              

              <g clipPath="url(#MacBookPro14--8_clip0-1-160)">
          
                  <Path d="M0 637V0H1226C852.996 421.171 253.248 600.154 0 637Z" fill="#191918"/>
                  
                  <g filter="url(#MacBookPro14--8_filter0-f-1-160)">
                      <Path d="M0 631.355C661.72 457.188 1059.79 137.882 1176.11 0H1512V522.501C974.981 728.26 280.242 768.224 0 762.486L-10.5 695.5L0 631.355Z" fill="url(#MacBookPro14--8_paint0-linear-1-160)"/>
                  </g>

                  <Path
                      d="M0 626.5C262.5 567.667 868 358 1190 -15"
                      stroke="url(#MacBookPro14--8_paint1-linear-1-160)"
                      strokeWidth="12"
                      strokeLinecap="round"
                  />


              </g>



            <Defs>
              <filter
                id="MacBookPro14--8_filter0-f-1-160"
                x="-12.5"
                y="-2"
                preserveAspectRatio="xMaxYMid slice"
                //width={"110%"}
                //height={"1100%"}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              
              <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
              />
                
              <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1_160"
              />
              
              </filter>
              <LinearGradient
                id="MacBookPro14--8_paint0-linear-1-160"
                x1={width}
                y1="17"
                x2="35.5"
                y2="701"
                gradientUnits="userSpaceOnUse"
              >
              <Stop offset="0.3" stopColor="#FAE090" />
              <Stop offset="0.380862" stopColor="#BD9448" />
              <Stop offset="0.900862" stopColor="#BD9448" />
              <Stop offset="1" stopColor="#3C2F1B" />
              </LinearGradient>
              <LinearGradient
                id="MacBookPro14--8_paint1-linear-1-160"
                x1="44.5"
                y1="100%"
                x2={width}
                y2="20"
                gradientUnits="userSpaceOnUse"
              >
                <Stop stopColor="#C7B177" />
                <Stop offset="0.827697" stopColor="white" />
                <Stop offset="1" stopColor="#F8E3A1" />
              </LinearGradient>

            </Defs>
            
          </Svg>
          
          </View>}

        </SafeAreaView>
        </Provider>



        
          

        
      )
    }
  }
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
