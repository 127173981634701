export const theme = {
    colors: {
      white: '#ffffff',
      bgTop: '#242423',
      bgBottom: '#0D0D0D',
      buttonBg: '#0C0C0C',
      buttonTop: '#FFFFFF',
      buttonMid: '#F4D995',
      buttonBottom: '#8C6629',
      border: '#E8CA84',
      bgBlur: '#616161',
      inputBg: '#C8CAC9',
      black: '#000000',
      backgroundLight: '#ebebf0',
      backgroundDark: '#232526',
      title: '#232526',
      subtitle: '#6c6c70',
      text: '#7c7c80',
      link: '#007AFF',
      error: '#d70015',
      success: '#43db5b',
    }, 
    pallete: {
      1: '#5ac8fa',
      2: '#007aff',
      3: '#4cd964',
      4: '#ffcc00',
      5: '#ff9500',
      6: '#5856d6',
      7: '#ff3b30',
      8: '#ff2d55',
      9: '#ebebf0',
      10:'#013a70',
      11:'#231161',
      12:'#f5b630'
    },
    openAI: {
      white: "#FFFFFF",
      light_green: "#9CCC65",
      medium_green: "#7CB342",
      dark_green: "#558B2F",
      light_orange: "#FFA726",
      medium_orange: "#FB8C00",
      dark_orange: "#F57C00",
      light_purple: "#7E57C2",
      medium_purple: "#5E35B1",
      dark_purple: "#512DA8"
    }
  }
  
  export const colors = theme.pallete