import React from 'react' 
import * as appJson from "../../app.json"
//const firebaseConfig = appJson.expo.web.config.firebase
const firebaseConfig = {
    "apiKey": "AIzaSyC9fRYtfYl6jCCBFK2vYAlsZD2_xJ6GMx0",
    "authDomain": "berich-f5aa0.firebaseapp.com",
    "projectId": "berich-f5aa0",
    "storageBucket": "berich-f5aa0.appspot.com",
    "messagingSenderId": "553563493924",
    "appId": "1:553563493924:web:ce3c71bb80d2f776d0aab1",
    "measurementId": "G-QKCWCJKKH7"
}

import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth();

const db = getFirestore(app)
import { getFirestore, collection, getDocs, setDoc, doc, getDoc, updateDoc, where, orderBy, query, documentId, limit } from 'firebase/firestore';

class Data extends React.Component {

    async verify(oobCode){
        const res = [];
        try {
            await verifyPasswordResetCode(auth, oobCode).then((email)=>{
                res.push(true)
            }).catch((e)=>{
                console.warn(e)
            })
        } catch (error) {
            console.warn(error)
        }
        return(res)
    }

    async reset(oobCode, newPass){
        const res = []
        console.warn('RESETTTINNGGGGGGG')
        try {
            await confirmPasswordReset(auth, oobCode, newPass).then(()=>{
                res.push(true)
            }).catch((err)=>{
                let error;
                if(err.code.includes("password")){
                    if(password.length<6){
                      error = "Password should be at least 6 characters"
                    }else{
                      error = "Please revise the password field"
                    }
                }else{
                    error = "Something went wrong. Please try again later"
                }
                res.push(false, error)
            })
        } catch (error) {
            res.push(false, "Something went wrong. Please try again later")
        }
        return(res)
    }

    async checkId(id){
        const res = [];
        const q = query(collection(db, "cards"), where(documentId(), "==", id), limit(1));
        console.log(q)
        await getDocs(q).then(async (docs)=>{
            if(!docs.empty){
                for (const userIdDoc of docs.docs) {
                    console.log(userIdDoc.data().uid)
                    await getDoc(doc(db, "users", userIdDoc.data().uid)).then(doc=>{
                        console.log('LOG!!!', doc.data())
                        res.push(true, doc.data())
                    })
                }
            }
        }).catch((e)=>{
            console.warn(e)
            res.push(false)
        })
        return(res)
    }

}

export default Data;