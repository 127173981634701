import React from "react";
import { Text, View, Dimensions, Image, SafeAreaView, Linking, TouchableOpacity, TextInput } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

const { width, height } = Dimensions.get("screen");

import { theme as tema } from "../../theme";
import Loading from "../core/Loading";

import Data from "../core/Data";

class ResetPass extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        loading: true,
        oobCode: this.props.route.params.oobCode,
        verified: false,

        success:'',
        error:'',
        fail:'',
        pass:'',
      }
    }
    
    componentDidMount(){
        const self = this
        setTimeout(function func(n){
            self.verify()
        }, 2000)
    }

    async verify(){
        console.log(this.state.oobCode)
        await new Data().verify(this.state.oobCode).then(res=>{
            console.error(this.state.oobCode, res)
            if(res[0]===true){
                console.warn('VERIFIED!!!')
                this.setState({verified:true})
            }else{
                console.warn('NOTTTTT VERIFIED!!!')
                this.fail()
            }
        }).catch((e)=>{
            console.warn('NOTTTTT VERIFIED!!!', e)
            this.fail()
        })
        this.setState({loading:false})
    }

    async reset(){
        this.setState({loading: true})
        await new Data().reset(this.state.oobCode, this.state.pass).then(res=>{
            console.log(res)
            if(res[0]===true){
                this.success()
            }else{
                this.setState({error: res[1]})
            }
        }).catch((err)=>{
            this.setState({error: 'Something went wrong. Please try again later'})
        })
        this.setState({loading:false})
    }

    success(){
        this.setState({success:'Your passcode is updated'})
        //const self = this
        setTimeout(function func(n){
            //this.props.navigation.popToTop()
            Linking.openURL('https://www.berichmember.com',"_self")
        }, 5000)
    }

    fail(){
        this.setState({fail:'Something went wrong. Please try again later'})
        //const self = this
        setTimeout(function func(n){
            //this.props.navigation.popToTop()
            Linking.openURL('https://www.berichmember.com',"_self")
        }, 5000)
    }

    render(){

        const { loading, error, success, fail } = this.state

        return(
            <>
            {loading?<Loading></Loading>:null}

            <style
                dangerouslySetInnerHTML={{
                __html:
                    `       
                
                    a{
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                    }

                    .title {
                        font-size: calc(20px + 4vw);         
                    }

                    .subtitle {
                        text-align:center;
                        font-size: calc(5px + 3vh);          
                    }
                    
                    b{   
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        letter-spacing: 2vw; 
                        font-size: calc(5px + 1.5vh); 
                    }
                     
                    .left{
                        top:5%;  
                        left:10%;  
                        position:absolute;
                    }
                    

                    .right{
                        text-align: right;
                        top:5%;  
                        right:0%;  
                        position:absolute;   
                    }

                    p{
                        font-size:calc(5px + 1.2vh);
                        line-height: calc(20px + 1vw);
                        z-index:1;
                    }

                    .endline{
                        font-family: IbarraRealNova_400Regular_Italic;           
                    }

                    .maintext{
                        font-family: Ibarra Real Nova, serif;    
                    }

                    span{              
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        box-decoration-break: clone;
                        -webkit-box-decoration-break: clone;

                    }

                    .visibledevice {display:none;}
                    .visibledesktop {display:display;}

                    @media (max-width : 768px) {
                        .visibledevice {display:block; -webkit-transform: rotate(90deg); width:105vw; z-index:-1; opacity:0.5; position:fixed; bottom:-28%; align-self:center;}
                        .incHeight {font-size:calc(8px + 2vw); line-height: calc(20px + 2vh);}
                        .mobile {text-align:center; width:70vw; height:100%; align-self:center; display: flex;}
                    }

                    @media (min-width : 768px) {
                        .desktop {text-align:left; width:45vw; height:100%; align-self:start; padding:40px;}
                        .visibledesktop {display:block; height:100%; position:absolute; align-self:flex-end; opacity:1; right:-100px;}
                    }

                    img{
                        transition: transform .2s;
                    }

                    .img2:hover{
                        cursor: pointer;
                        transform: scale(1.2);
                    }

                    `
                }}
            />

            <View style={{flex:1}}>
                <LinearGradient colors={[tema.colors.bgTop, tema.colors.bgBottom]} style={{flex:1}}>
                {/*<LinearGradient colors={[tema.colors.bgBlur, 'transparent']} style={{position:'relative', flex:1 }}></LinearGradient>*/}
                <View style={{position:'absolute', top:width*0.01, left:width*0.015, height:width*0.1, width:width*0.1, borderLeftWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}><b className="left">MMXXIII</b></View>
                <View style={{position:'absolute', top:width*0.01, right:width*0.015, height:width*0.1, width:width*0.1, borderRightWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}><b className="right">MMXXIII</b></View>

                    <View style={{flex:1, justifyContent:'center'}}>
                        <a style={{alignSelf:'center'}} className="title">RESET PASSCODE</a>
                    </View>

                    {success?
                    <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                        <succ style={{fontFamily:"IbarraRealNova_400Regular", fontSize:"clamp(16px, 5vw, 40px)", alignSelf:'center', paddingBottom:10, color:tema.colors.success}}>{success}</succ>
                    </View>
                    :(fail?
                    <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                        <err style={{fontFamily:"IbarraRealNova_400Regular", fontSize:"clamp(16px, 5vw, 40px)", alignSelf:'center', paddingBottom:10, color:tema.colors.error}}>{fail}</err>
                    </View>
                    :<View style={{flex:1, justifyContent:'flex-start', alignItems:'center'}}>

                        <View style={{width:'calc(110px + 30vw)', height:"calc(30px + 3vh)", maxHeight:"4vh", minHeight:"50px"}}>
                            <TextInput
                                style={{borderWidth:1.5, borderColor:tema.colors.border, backgroundColor:tema.colors.inputBg, borderRadius:360, fontFamily:'IbarraRealNova_700Bold', color:'#818181', padding:10}}
                                placeholder="New passcode"
                                secureTextEntry
                                textContentType='newPassword'
                                placeholderTextColor={'#818181'}
                                onChangeText={(text)=>this.setState({pass: text})}
                                value={this.state.pass}
                                multiline={false}
                                numberOfLines={1}
                                //maxLength={500}
                                returnKeyType="done"
                                textAlignVertical="center"
                                onFocus={()=>this.setState({error:''})}
                            />
                        </View>

                        <span style={{width:'calc(110px + 30vw)', height:"calc(30px + 3vh)", maxHeight:"4vh", minHeight:"40px"}}>
                        <TouchableOpacity disabled={this.state.freeze} onPress={()=>this.reset()} style={{borderRadius:45, width:'100%', height:'100%'}}>
                            <View style={{backgroundColor:tema.colors.buttonBg, borderRadius:45, borderColor:tema.colors.border, borderWidth:1.5, width:'100%', height:'100%', justifyContent:'center', alignItems:'center'}}>
                                <a>Confirm</a>
                            </View>
                        </TouchableOpacity>
                        </span>

                        {error?<err style={{fontFamily:"IbarraRealNova_400Regular", fontSize:"clamp(10px, 2.5vw, 15px)", alignSelf:'center', paddingTop:10, color:tema.colors.error}}>{error}</err>:null}

                
                    </View>)}
           

                </LinearGradient>
            </View>
            </>
        )

    }
}

export default ResetPass;