import React from "react";
import { Text, View, Dimensions, Image, SafeAreaView, Linking } from "react-native";

import { LinearGradient as LG } from "expo-linear-gradient";
const { width, height } = Dimensions.get("screen");

import { theme as tema } from "../../theme";

class Order2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    //redirect and dont set prop
    //only set to true (cond rendering) if verified
    console.log('MOUNTED2')
  }

  render() {

    return (

        <>
            <style
                dangerouslySetInnerHTML={{
                __html:
                    `       
                
                    a{
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                    }

    
                    .subtitle {
                        text-align:center;
                        font-size: calc(5px + 1.5vh);          
                    }
                    
                    b{   
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        letter-spacing: 2vw; 
                        font-size: calc(5px + 1.5vh); 
                    }
                     
                    .left{
                        top:5%;  
                        left:10%;  
                        position:absolute;
                    }
                    

                    .right{
                        text-align: right;
                        top:5%;  
                        right:0%;  
                        position:absolute;   
                    }

                    p{
                        font-size:calc(5px + 1.2vh);
                        line-height: calc(20px + 1vw);
                        z-index:1;
                    }

                    .endline{
                        font-family: IbarraRealNova_400Regular_Italic;           
                    }

                    .maintext{
                        font-family: Ibarra Real Nova, serif;    
                    }

                    span{              
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        box-decoration-break: clone;
                        -webkit-box-decoration-break: clone;
                    }

                    .visibledevice {display:none;}
                    .visibledesktop {display:display;}

                    @media (max-width : 768px) {
                        .title1 { font-size: clamp(15px, 12vw, 15vw); }
                        .sub1 { font-size: clamp(10px, 4.5vw, 7.5vw); }
                        .visibledevice {display:block; -webkit-transform: rotate(90deg); width:105vw; z-index:-1; opacity:0.5; position:fixed; bottom:-28%; align-self:center;}
                        .incHeight {font-size:calc(8px + 2vw); line-height: calc(20px + 2vh);}
                        .mobile {text-align:center; width:70vw; height:100%; align-self:center; display: flex;}
                    }

                    @media (min-width : 768px) {
                        .title2 { font-size: clamp(60px, 10vw, 7vw);  }
                        .sub2 { font-size: clamp(20px, 2vw, 3vw); }
                        .desktop {text-align:left; width:45vw; height:100%; align-self:start; padding:40px;}
                        .visibledesktop {display:block; height:100%; position:absolute; align-self:flex-end; opacity:1; right:-100px;}
                    }

                    img{
                        transition: transform .2s;
                    }

                    .img2:hover{
                        cursor: pointer;
                        transform: scale(1.2);
                    }

                    `
                }} 
            />
   
        <View style={{flex:1}}>
        
            <LG colors={[tema.colors.bgTop, tema.colors.bgBottom]} style={{flex:1}}>
            {/*<LG colors={[tema.colors.bgBlur, 'transparent']} style={{position:'relative', flex:1 }}></LG>*/}
            <View style={{position:'absolute', top:width*0.01, left:width*0.015, height:'30%', width:'20%', borderLeftWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}>
                <b className="left">MMXXIII</b>
            </View>      
            <View style={{position:'absolute', top:width*0.01, right:width*0.015, height:'30%', width:'20%', borderRightWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}> 
                <b className="right">MMXXIII</b>
            </View>

            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>

                <View style={{flex:1, justifyContent:'center'}}>
                    <View style={{flex:1, justifyContent:'flex-end'}}>
                        <a className="title1 title2">Order received</a> 
                    </View>  

                    <div style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                        <p style={{alignItems:'center', textAlign:'center'}} className="subtite">
                            <span style={{alignItems:'center', textAlign:'center'}} className="sub1 sub2">
                                Congratulations, we will be in touch shortly!
                            </span>
                            <p style={{alignSelf:'center'}} className="incHeight endline"><span>Sincerely, concierge at BeRich</span></p>
                        </p>
                    </div>
                </View>


                <View style={{flex:1, justifyContent:'center', width:'100%', alignItems:'center', padding:40}}>
                    <View style={{backgroundColor:'#0D0D0D', width:'100%', height:'100%', borderWidth:0.5, borderColor:"#E8CA84", overflow:'hidden'}}>

                        <div style={{alignItems:'center', display:'flex', flexDirection:'column'}} className="mobile desktop">
                            <p className="incHeight maintext" style={{alignSelf:'center'}}>
                                <span>
                                    Verify your wealth and authenticate your status with The Elite RichCard made of 24K gold. Hold it to a phone and your exclusive member-verification site will be shown. This is a symbol of achievement you can carry with you wherever you go! 
                                    <br></br>
                                </span>
                            </p>
                        </div>
                        

                

                        <img className="visibledesktop visibledevice" src={require('../srcassets/leaf.png')}></img>
                    </View>
                </View>

        
            </View>
                

            </LG>
        </View>

        </>
        
    );
  }
}

export default Order2;
//`${width}`