import React from 'react'
import { View, Text } from 'react-native'

class By extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  render(){

    return(
      <Text style={{color:/*"#191918"*/'#A3A3A3', position:'absolute', bottom:15, fontFamily:'IbarraRealNova_400Regular', fontSize:12, textAlign:'center', alignSelf:'center'}}>©2023 BeRich</Text>
    )

  }
} 

export default By;