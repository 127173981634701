import React from 'react'
import { View } from 'react-native'
import {  Portal } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import { theme as tema } from '../../theme'
import By from './trade';
import LottieView from 'react-native-web-lottie'

class Loading extends React.Component {
  constructor(props){     //first to be called whenever a component is created
    super(props);
    this.state = {
    }
  }

  render(){

    return(
        <Portal>
            <LinearGradient colors={[tema.colors.bgTop, tema.colors.bgBottom]} style={{flex:1, justifyContent:'center'}}>

                <LinearGradient colors={[tema.colors.bgBlur, 'transparent']} style={{position:'absolute', top:'-10%', flex:1, height:'100%', width:'100%'}}>
                </LinearGradient>

                <View style={{position:'absolute', top:'10%', height:'30%', width:'30%', borderLeftWidth:0.5, borderTopWidth:0.5, borderColor:'white', left:'5%', borderColor:'#E8CA84'}}>
                </View>

                <LottieView source={require('../srcassets/loading.json')}
                  speed={0.8}
                  autoSize={false}
                  //resizeMode={'center'}
                  autoPlay={true}  
                  loop={true}
                  style={{width:'20%', alignSelf:'center', opacity:1}} 
                />

                <View style={{position:'absolute', bottom:'10%', height:'30%', width:'20%', borderRightWidth:0.5, borderBottomWidth:0.5, borderColor:'white', right:'5%', borderColor:'#E8CA84'}}>
                </View>

            </LinearGradient>
        <By/>
        </Portal>
    )

  }
} 

export default Loading;