import React from "react";
import { Text, View, Dimensions, Image, SafeAreaView, Linking } from "react-native";

import { LinearGradient as LG } from "expo-linear-gradient";
const { width, height } = Dimensions.get("screen");

import Data from "../core/Data";
import { avatars } from "../core/info2";

import { theme as tema } from "../../theme";
import Loading from "../core/Loading";

class Verified extends React.Component {          
  constructor(props) {
    super(props);   
    this.state = {};
  }

    async componentDidMount(){
        console.log('VERIFICATION ' + (Math.random()*100).toFixed(0).toString().slice(0,4))
        try {
            const id = this.props.route.params.i
            console.log(id)
            if(id){
                await new Data().checkId(id).then(res=>{
                    console.warn('RESSS!!!!', res[0])
                    if(res[0]===true){
                        this.setState({v: true, prof: res[1]})
                        console.warn(res[1])
                    }else{
                        this.exit('inner')
                    }
                }).catch((e)=>{
                    console.error(e)
                    this.exit('first')
                })
            }else{
                this.exit('NO ID')
            }
        } catch (error) {
            this.exit('OUTER LAYER')
        }
    }

    exit(e){
        //console.warn('WRONG ID', e)
        //Response.redirect()
        Linking.openURL('https://www.berichmember.com',"_self")
    }

  render() {

    const { v, prof } = this.state

    return (

        <>
            <style
                dangerouslySetInnerHTML={{
                __html:
                    `       
                
                    .a{
                        text-align: center;
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                    }

                    .title {
                        font-size: calc(5px + 7vh + 5vw);         
                    }

                    .subtitle {
                        text-align:center;
                        font-size: calc(5px + 1.5vh + 1vw);          
                    }
                    
                    b{   
                        font-family: Ibarra Real Nova, serif;           
                        background: linear-gradient(to top, #8C6629,#F4D995, #FFFFFF);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        letter-spacing: 2vw; 
                        font-size: calc(5px + 1.5vh); 
                    }
                     
                    .left{
                        top:5%;  
                        left:10%;  
                        position:absolute;
                    }
                    

                    .right{
                        text-align: right;
                        top:5%;  
                        right:0%;  
                        position:absolute;   
                    }

                    .endline{
                        font-family: IbarraRealNova_400Regular_Italic;           
                    }

                    .maintext{
                        font-family: Ibarra Real Nova, serif;    
                    }

                    .visibledevice {display:none;}
                    .visibledesktop {display:display;}

                    @media (max-width : 768px) {
                        .visibledevice {display:block; -webkit-transform: rotate(90deg); width:105vw; z-index:-1; opacity:0.5; position:fixed; bottom:-28%; align-self:center;}
                        .incHeight {font-size:calc(8px + 2vw); line-height: calc(20px + 2vh);}
                        .mobile {text-align:center; width:70vw; height:100%; align-self:center; display: flex;}
                    
                        .card1{
                            width:calc(1px + 30vh + 20vw);
                        }

                        .mask1{
                            width:calc(1px + 4vh + 4vw);
                        }
                    }

                    @media (min-width : 768px) {

                        .card2{
                            width:calc(1px + 35vh + 20vw);
                        }

                        .mask2{
                            width:calc(1px + 4vh + 4vw);
                        }

                        .desktop {text-align:left; width:45vw; height:100%; align-self:start; padding:40px;}
                        .visibledesktop {display:block; height:100%; position:absolute; align-self:flex-end; opacity:1; right:-100px;}
                    }

                    .part{
                    }

                    .sumcard{
                        flex-direction:column;
                        display:flex;
                        justify-content:space-between;
                        transition: transform .2s;
                    }

                    .img2:hover{
                        transform: scale(1.2);
                    }

                    .white{
                        font-size:calc(1px + 0.8vh + 1vw);
                    }

                    `
                }}
            />

 
        {v?
        <View style={{flex:1, height:'100%'}}>
            <LG colors={[tema.colors.bgTop, tema.colors.bgBottom]} style={{flex:1}}>
            {/*<LG colors={[tema.colors.bgBlur, 'transparent']} style={{position:'relative', flex:1 }}></LG>*/}
            <View style={{position:'absolute', top:width*0.01, left:width*0.015, height:'30%', width:'20%', borderLeftWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}>
                <b className="left">MMXXIII</b>
            </View>
            <View style={{position:'absolute', top:width*0.01, right:width*0.015, height:'30%', width:'20%', borderRightWidth:0.5, borderTopWidth:0.5, borderColor:'white', borderColor:'#E8CA84'}}> 
                <b className="right">MMXXIII</b>
            </View>

            <View style={{alignItems:'center', justifyContent:'space-between', flex:1}}>

                    <View style={{flex:1, justifyContent:'center', paddingTop:'5%'}}>
                    <a className="subtitle a">YOU ARE VERIFIED TO</a>
                    <a className="title">BeRich</a>
                    </View>
      
                        {/*<div className="sumcard">

                  
                            <img className="mask1 mask2" src={avatars[0]}></img>
              
    
                            <img className="card1 card2" src={require('../srcassets/card.png')}></img>
                          
                            <p className="white">Mr. Gupta</p>
                        
                        </div>*/}

                <View style={{flex:1, height:'100%'}}>
                <View
                    style={{
                    alignItems:'center',
                    justifyContent:'center',
                    }}>

                    <View style={{position:'absolute', zIndex:1, bottom:'4%', right:'7%'}}>
                        <img className="mask1 mask2" style={{alignSelf:'center'}} src={avatars[prof.index]}></img>
                    </View>
    
                    <View style={{position:'absolute', zIndex:1, top:0, left:'4%'}}>
                        <p className="white" style={{textAlign:'center', color:tema.colors.black, fontFamily:'IbarraRealNova_400Regular'}}>
                            BeRich
                        </p>
                    </View>

                    <View style={{position:'absolute', zIndex:1, bottom:'5%', left:'4%'}}>
                        <p className="white" style={{textAlign:'center', color:'white', fontFamily:'IbarraRealNova_700Bold'}}>
                            {prof.title + ' ' + prof.name}
                        </p>
                    </View>

                    <img className="card1 card2" style={{alignSelf:'center'}} src={require('../srcassets/card.png')}></img>
                
                </View>
                <p className="a">{prof.title + ' ' + prof.name} is an exclusive member since {prof.since}</p>
                </View>

                <View style={{flex:1, paddingBottom:40}}></View>
                        

               
                        

            </View>
                

            </LG>
        </View>
        :<Loading></Loading>}

        </>
        
    );
  }
}

export default Verified;
//`${width}`